import { render, staticRenderFns } from "./BaseHero.vue?vue&type=template&id=8a4c8536"
import script from "./BaseHero.vue?vue&type=script&lang=js"
export * from "./BaseHero.vue?vue&type=script&lang=js"
import style0 from "./BaseHero.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImage: require('/vercel/path0/components/Base/Image/BaseImage.vue').default})
