import { render, staticRenderFns } from "./index.vue?vue&type=template&id=ea9944c6"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseHero: require('/vercel/path0/components/Base/Hero/BaseHero.vue').default,FlexibleSections: require('/vercel/path0/components/FlexibleSections/FlexibleSections.vue').default,TemplateDefault: require('/vercel/path0/components/TemplateDefault/TemplateDefault.vue').default,QueryHome: require('/vercel/path0/components/Query/QueryHome.vue').default})
